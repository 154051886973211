<template>
  <div
    class="about-containner"
    :class="is_mobile ? 'm-about-containner' : 'pc-about-containner'"
  >
    <div class="banner">
      <img class="banner-bg" :src="is_mobile?banner_m:banner" />
      <div class="banner-title">
        <div class="title-na">{{ $t("about_us.banner_title") }}</div>
        <div class="title-tip">{{ $t("about_us.banner_tip") }}</div>
      </div>
    </div>
    <div class="sub-nav-box" v-if="nav_is_fixed"></div>
    <div class="sub-nav-box" :class="nav_is_fixed ? 'pro-nav-bar-fixed' : ''">
      <div
        class="sub-nav-item"
        :class="nav_act == 0 ? 'sub-nav-item-act' : ''"
        @click="scrollToProfile"
      >
        <img
          v-if="!is_mobile"
          class="sub-nav-icon"
          :src="nav_act == 0 ? nav_icon_a_act : nav_icon_a"
        />
        {{ $t("about_us.company_profile") }}
      </div>
      <div
        class="sub-nav-item"
        :class="nav_act == 1 ? 'sub-nav-item-act' : ''"
        @click="scrollToMission"
      >
        <img
          v-if="!is_mobile"
          class="sub-nav-icon"
          :src="nav_act == 1 ? nav_icon_b_act : nav_icon_b"
        />
        {{ $t("about_us.mission_vision") }}
      </div>
      <div
        class="sub-nav-item"
        :class="nav_act == 2 ? 'sub-nav-item-act' : ''"
        @click="scrollToCourse"
      >
        <img
          v-if="!is_mobile"
          class="sub-nav-icon"
          :src="nav_act == 2 ? nav_icon_c_act : nav_icon_c"
        />
        {{ $t("about_us.development_course") }}
      </div>
      <div
        v-if="qualitment_list&&qualitment_list.length>0"
        class="sub-nav-item"
        :class="nav_act == 3 ? 'sub-nav-item-act' : ''"
        @click="scrollToQualification"
      >
        <img
          v-if="!is_mobile"
          class="sub-nav-icon"
          :src="nav_act == 3 ? nav_icon_d_act : nav_icon_d"
        />
        {{ $t("about_us.qualification") }}
      </div>
      <div
        class="sub-nav-item"
        :class="nav_act == 4 ? 'sub-nav-item-act' : ''"
        @click="scrollToDevelopment"
      >
        <img
          v-if="!is_mobile"
          class="sub-nav-icon"
          :src="nav_act == 4 ? nav_icon_e_act : nav_icon_e"
        />
        {{ $t("about_us.development_center") }}
      </div>
      <div
        class="sub-nav-item"
        :class="nav_act == 5 ? 'sub-nav-item-act' : ''"
        @click="scrollToContact"
      >
        <img
          v-if="!is_mobile"
          class="sub-nav-icon"
          :src="nav_act == 5 ? nav_icon_f_act : nav_icon_f"
        />
        {{ $t("about_us.contact_us") }}
      </div>
    </div>
    <div class="about-intro" id="profile">
      <img :src="is_mobile ? intro_bg_m : intro_bg" class="about-intro-bg" />
      <div class="about-intr-txt">{{ $t("about_us.introduction") }}</div>
    </div>
    <div class="about-mission" id="mission">
      <div class="mission-item">
        <div class="mi-it-box">
          <img class="mi-it-icon" :src="mission_icon_a" />
        </div>
        <div class="mission-name">{{ $t("about_us.vision") }}</div>
        <div class="mission-tip">{{ $t("about_us.vision_tip") }}</div>
      </div>
      <div class="mission-item">
        <div class="mi-it-box">
          <img class="mi-it-icon mi-it-icon-b" :src="mission_icon_b" />
        </div>
        <div class="mission-name">{{ $t("about_us.mission") }}</div>
        <div class="mission-tip">{{ $t("about_us.mission_tip") }}</div>
      </div>
      <div class="mission-item">
        <div class="mi-it-box">
          <img class="mi-it-icon mi-it-icon-c" :src="mission_icon_c" />
        </div>
        <div class="mission-name">{{ $t("about_us.sense_value") }}</div>
        <div class="mission-tip">{{ $t("about_us.sense_value_tip") }}</div>
      </div>
    </div>
    <div class="about-course" id="course">
      <img
        class="about-course-bg"
        :src="is_mobile ? about_course_bg_m : about_course_bg"
      />
      <div class="about-msg-box">
        <div class="about-msg-title">{{ $t("about_us.development_course") }}</div>
        <div class="about-course-box">
          <div class="course-box">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide course-month"
                :class="i == course_list.length - 1 ? 'course-month-last' : ''"
                v-for="(item, i) in course_list"
                :key="i"
              >
                <div class="course-year" v-if="item.year">{{ item.year }}</div>
                <div class="course-mon-name" v-if="$t('language.shot_name') == 'en'">
                  {{ en_month[item.month-1] }}
                </div>
                <div class="course-mon-name" v-if="$t('language.shot_name') == 'cn'">
                  {{ cn_month[item.month-1] }}
                </div>
                <div class="course-mon-desc" v-for="(it, j) in item.content" :key="j">
                  {{ it["content_" + $t("language.shot_name")] }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="course-year-list">
          <span
            v-for="item in course_year"
            :key="item"
            :class="current_year == item ? 'active' : ''"
            @click="scrollToYear(item)"
            >{{ item }}</span
          >
        </div>
      </div>
    </div>
    <div class="about-quali" id="qualification" v-if="qualitment_list&&qualitment_list.length>0">
      <div class="about-msg-title">{{ $t("about_us.qualification") }}</div>
      <div class="swiper qualification-wrap">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, i) in qualitment_list" :key="i">
            <div class="quali-box" v-for="(it, j) in item" :key="j">
              <img class="quali-img" :src="it.image_url" />
              <div class="quali-name">{{ it["desc_" + $t("language.shot_name")] }}</div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="mulit-office" id="development">
      <img
        class="muilt-office-bg"
        :src="$t('language.shot_name') == 'en' ? about_multi_bg_en : about_multi_bg"
      />
      <div class="about-msg-title">{{ $t("about_us.multip_dev_center") }}</div>
    </div>
    <div class="about-contact" id="contact">
      <img class="about-c-bg" :src="is_mobile ? about_concact_bg_m : about_concact_bg" />
      <div class="about-msg-box">
        <div class="about-msg-title">{{ $t("about_us.contact_us") }}</div>
        <div class="about-contact-way">
          <div class="about-cw-item">
            <img v-if="!is_mobile" class="about-cw-icon" :src="about_concact_icona_a" />
            <div class="about-cw-msg">
              <div class="about-cw-title">{{ $t("about_us.business_cooperation") }}</div>
              <div class="about-cw-txt">thunderx@thunderxauto.com</div>
            </div>
          </div>
          <div class="about-cw-item">
            <img v-if="!is_mobile" class="about-cw-icon" :src="about_concact_icona_c" />
            <div class="about-cw-msg">
              <div class="about-cw-title">{{ $t("about_us.mobile") }}</div>
              <div class="about-cw-txt">+86-0512-8588 8896</div>
            </div>
          </div>
        </div>
        <div class="about-office">
          <div class="office-item">
            <img class="office-img" :src="about_office_a" />
            <div class="office-title">{{ $t("about_us.department_a") }}</div>
            <div class="office-txt">{{ $t("about_us.department_a_add") }}</div>
          </div>
          <div class="office-item">
            <img class="office-img" :src="about_office_b" />
            <div class="office-title">{{ $t("about_us.department_b") }}</div>
            <div class="office-txt">{{ $t("about_us.department_b_add") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import Swiper from "swiper";
gsap.registerPlugin(ScrollToPlugin);
export default {
  name: "About",
  props: {},
  data() {
    return {
      banner: require("@/assets/about-banner.png"),
      banner_m: require("@/assets/about-banner-m.png"),
      nav_icon_a: require("@/assets/about-nav-a.png"),
      nav_icon_b: require("@/assets/about-nav-b.png"),
      nav_icon_c: require("@/assets/about-nav-c.png"),
      nav_icon_d: require("@/assets/about-nav-d.png"),
      nav_icon_e: require("@/assets/about-nav-e.png"),
      nav_icon_f: require("@/assets/about-nav-f.png"),
      nav_icon_a_act: require("@/assets/about-nav-a-act.png"),
      nav_icon_b_act: require("@/assets/about-nav-b-act.png"),
      nav_icon_c_act: require("@/assets/about-nav-c-act.png"),
      nav_icon_d_act: require("@/assets/about-nav-d-act.png"),
      nav_icon_e_act: require("@/assets/about-nav-e-act.png"),
      nav_icon_f_act: require("@/assets/about-nav-f-act.png"),
      intro_bg: require("@/assets/pro-aikit-data-bg.png"),
      intro_bg_m: require("@/assets/pro_data_bg_m.png"),

      mission_icon_a: require("@/assets/about-icon-a.png"),
      mission_icon_b: require("@/assets/about-icon-b.png"),
      mission_icon_c: require("@/assets/about-icon-c.png"),
      about_course_bg: require("@/assets/i-bg-a.png"),
      about_course_bg_m: require("@/assets/series-bg.png"),

      about_multi_bg: require("@/assets/about-map.png"),
      about_multi_bg_en: require("@/assets/about-map-en.png"),
      about_concact_bg: require("@/assets/takla-data-bg.png"),
      about_concact_bg_m: require("@/assets/pro_data_bg_m.png"),

      about_concact_icona_a: require("@/assets/about-contact-a.png"),
      about_concact_icona_c: require("@/assets/about-contact-c.png"),
      about_office_a: require("@/assets/about-office-a.png"),
      about_office_b: require("@/assets/about-office-b.png"),

      nav_is_fixed: false,

      nav_act: 0,

      course_list: [],
      cn_month: [
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月",
      ],
      en_month: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      show_course: false,

      course_year: [],
      current_year: "",
      qualitment_list: [],
      course_swiper_instance: null,
    };
  },
  methods: {
    scrollToYear(item) {
      this.current_year = item;
      let active_index = 0;
      let i = 0;
      this.course_list.forEach((element) => {
        if (element.year == item) {
          active_index = i;
        }
        i += element.content.length;
      });
      this.course_swiper_instance.slideTo(active_index);
    },
    scrollToProfile() {
      gsap.to(window, { duration: 0.5, scrollTo: { y: "#profile", offsetY: 65*this.scale } });
      this.nav_act = 0;
    },
    scrollToMission() {
      gsap.to(window, { duration: 0.5, scrollTo: { y: "#mission", offsetY: 65*this.scale } });
      this.nav_act = 1;
    },
    scrollToCourse() {
      gsap.to(window, { duration: 0.5, scrollTo: { y: "#course", offsetY: 65*this.scale } });
      this.nav_act = 2;
    },
    scrollToQualification() {
      gsap.to(window, { duration: 0.5, scrollTo: { y: "#qualification", offsetY: 65*this.scale } });
      this.nav_act = 3;
    },
    scrollToDevelopment() {
      gsap.to(window, { duration: 0.5, scrollTo: { y: "#development", offsetY: 65*this.scale } });
      this.nav_act = 4;
    },
    scrollToContact() {
      gsap.to(window, { duration: 0.5, scrollTo: { y: "#contact", offsetY: 65*this.scale } });
      this.nav_act = 5;
    },
  },
  components: {},
  mounted() {
    let that = this;
    window.addEventListener("scroll", function () {
      const scroll_top = that.getScrollTop();
      if (scroll_top < that.rate * 600) {
        that.nav_is_fixed = false;
      } else {
        that.nav_is_fixed = true;
        if (!that.is_mobile) {
          if (scroll_top < that.rate * 950) {
            that.nav_act = 0;
          } else if (scroll_top > that.rate * 950 && scroll_top < that.rate * 1280) {
            that.nav_act = 1;
          } else if (scroll_top > that.rate * 1280 && scroll_top < that.rate * 1920) {
            that.nav_act = 2;
          } else {
            if(!that.qualitment_list||that.qualitment_list.length<=0){
              if (scroll_top > that.rate * 1920 && scroll_top < that.rate * 2900) {
                that.nav_act = 4;
              } else if (scroll_top > that.rate * 2900) {
                that.nav_act = 5;
              }
            }else{
              if (scroll_top > that.rate * 1920 && scroll_top < that.rate * 2580) {
                that.nav_act = 3;
              } else if (scroll_top > that.rate * 2580 && scroll_top < that.rate * 3660) {
                that.nav_act = 4;
              } else if (scroll_top > that.rate * 3660) {
                that.nav_act = 5;
              }
            }
          }
        } else {
          if (scroll_top < that.rate * 1240) {
            that.nav_act = 0;
          } else if (scroll_top > that.rate * 1240 && scroll_top < that.rate * 1550) {
            that.nav_act = 1;
          } else if (scroll_top > that.rate * 1550 && scroll_top < that.rate * 2200) {
            that.nav_act = 2;
          } else {
            if(!that.qualitment_list||that.qualitment_list.length<=0){
              if (scroll_top > that.rate * 2200 && scroll_top < that.rate * 3000) {
                that.nav_act = 4;
              } else if (scroll_top > that.rate * 3000) {
                that.nav_act = 5;
              }
            }else{
              if (scroll_top > that.rate * 2200 && scroll_top < that.rate * 2670) {
                that.nav_act = 3;
              } else if (scroll_top > that.rate * 2670 && scroll_top < that.rate * 3460) {
                that.nav_act = 4;
              } else if (scroll_top > that.rate * 3460) {
                that.nav_act = 5;
              }
            }
          }
        }
      }
    });

    this.$router.afterEach(() => {
      if (this.$route.hash == "#profile") {
        this.scrollToProfile();
      } else if (this.$route.hash == "#mission") {
        this.scrollToMission();
      } else if (this.$route.hash == "#course") {
        this.scrollToCourse();
      } else if (this.$route.hash == "#contact") {
        this.scrollToContact();
      }
    });

    this.api.courseList().then((res) => {
      if (res.code == 200) {
        let purpose = [];
        this.course_year = [];
        for (let i = 0; i < res.data.data.length; i++) {
          let current = res.data.data[i];
          if (i == 0) {
            purpose.push({
              year: current.year,
              month: current.month,
              content: [
                {
                  content_cn: current.content_cn,
                  content_en: current.content_en,
                },
              ],
            });
            this.course_year.push(current.year);
          } else {
            let previous = res.data.data[i - 1];
            //上一条一条
            if (previous.year != current.year) {
              //不同年 放入数组
              purpose.push({
                year: current.year,
                month: current.month,
                content: [
                  {
                    content_cn: current.content_cn,
                    content_en: current.content_en,
                  },
                ],
              });
              this.course_year.push(current.year);
            } else {
              if (previous.month == current.month) {
                const purpose_last = purpose[purpose.length - 1];
                purpose_last.content.push({
                  content_cn: current.content_cn,
                  content_en: current.content_en,
                });
              } else {
                purpose.push({
                  month: current.month,
                  content: [
                    {
                      content_cn: current.content_cn,
                      content_en: current.content_en,
                    },
                  ],
                });
              }
            }
          }
          that.current_year = this.course_year[0];
        }

        that.course_list = purpose;
        this.$nextTick(() => {
          let pre_view = 4;
          if (this.is_mobile) {
            pre_view = 2;
          }
          this.show_course = true;
          this.course_swiper_instance = new Swiper(".course-box", {
            speed: 300,
            slidesPerView: pre_view,
            free: true,
            on: {
              slideChange: () => {
                that.active_index = this.course_swiper_instance;
              },
            },
          });
        });
      }
    });

    this.api.certificationList().then((res) => {
      if (res.code == 200) {
        let purpose = [];
        res.data.data.forEach((element) => {
          if (purpose.length == 0) {
            purpose.push([element]);
          } else {
            let last = purpose[purpose.length - 1];
            if (last.length < 4) {
              last.push(element);
            } else {
              purpose.push([element]);
            }
          }
        });
        that.qualitment_list = purpose;
        this.$nextTick(() => {
          new Swiper(".swiper", {
            speed: 2000,
            loop: true,
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },
          });
        });
      }
    });
  },
};
</script>
<style lang="less">
.about-containner {
  margin: 0px;
}

.banner {
  position: relative;
  height: 1920px;
  height: 500px;
  overflow: hidden;
}
.banner-bg {
  position: absolute;
  height: 1920px;
  height: 500px;
  z-index: 0;
  left: 0px;
  top: 0px;
}
.banner-title {
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 1;
  top: 100px;
  text-align: center;
}
.title-na {
  font-size: 60px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 30px;
}
.title-tip {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 16px;
  color: rgba(255, 255, 255, 1);
}
.sub-nav-box {
  display: flex;
  width: 1920px;
  justify-content: center;
  height: 90px;
  opacity: 1;
  align-items: center;
  background: rgba(31, 31, 31, 1);
}
.sub-nav-item {
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  align-items: center;
  padding: 0px 60px;
  cursor: pointer;
  line-height: 24px;
}
.sub-nav-item-act {
  color: #7dbbd7;
}
.sub-nav-icon {
  height: 23px;
  margin-right: 10px;
}
.about-intro {
  height: 353px;
  width: 1920px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-intro-bg {
  position: absolute;
  width: 1920px;
  height: 675px;
  z-index: 0;
  bottom: 0px;
  left: 0px;
}
.about-intr-txt {
  z-index: 1;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 34px;
  color: rgba(255, 255, 255, 1);
  white-space: pre-wrap;
  text-align: center;
}
.about-mission {
  position: relative;
  width: 1920px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 325px;
  opacity: 1;
  background: rgba(26, 26, 26, 1);
}
.mission-item {
  width: 380px;
}
.mi-it-box {
  height: 85px;
  width: 380px;
  overflow: hidden;
}
.mi-it-icon {
  height: 85px;
  display: block;
  margin: 0 auto;
}
.mi-it-icon-b {
  height: 65px;
  margin-top: 5px;
}

.mi-it-icon-c {
  height: 68px;
  margin-top: 3px;
}
.mission-name {
  font-size: 24px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 15px;
  margin-top: 20px;
}
.mission-tip {
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}
.about-course {
  position: relative;
  height: 645px;
}
.about-course-bg {
  position: absolute;
  height: 645px;
  width: 1920px;
  z-index: 0;
  left: 0px;
  bottom: 0px;
}
.about-course-box {
  width: 1720px;
  height: 350px;
  margin: 20px auto;
}
.course-box {
  display: flex;
  padding-left: 220px;
}
.course-month {
  position: relative;
  padding-top: 40px;
  width: 350px;
  display: inline-block;
  border-top: 2px solid rgba(255, 255, 255, 1);
  text-align: left;
  margin-top: 120px;
}
.course-month-last {
  border: none;
}
.course-month::before {
  position: absolute;
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 14px;
  top: -8px;
  left: -7px;
  content: "";
  opacity: 1;
  background: rgba(255, 255, 255, 1);
}
.course-month::after {
  position: absolute;
  display: block;
  width: 24px;
  height: 24px;
  opacity: 1;
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 24px;
  top: -14px;
  left: -13px;
  content: "";
}
.course-mon-name {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 2px;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 15px;
}
.course-year {
  position: absolute;
  top: -90px;
  left: -10px;
  font-size: 70px;
  font-weight: 700;
  letter-spacing: 2px;
  color: rgba(255, 255, 255, 1);
}
.course-mon-desc {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  width: 95%;
  line-height: 26px;
  color: rgba(255, 255, 255, 1);
}
.about-msg-box {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  left: 0px;
  top: 0px;
  z-index: 1;
}
.about-msg-title {
  position: relative;
  font-size: 44px;
  color: #fff;
  margin-top: 80px;
  z-index: 11;
}
.course-year-list {
  position: absolute;
  left: 220px;
  bottom: 60px;
  z-index: 11;
  span.active {
    font-size: 32px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    opacity: 1;
    border-bottom: solid 1px #fff;
    margin-right: 25px;
  }
  span {
    opacity: 0.6;
    font-size: 24px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    margin-right: 25px;
    cursor: pointer;
  }
}
.about-quali {
  position: relative;
  overflow: hidden;
  height: 687px;
  background-color: #1a1a1a;
}
.mulit-office {
  position: relative;
  height: 986px;
  overflow: hidden;
}
.muilt-office-bg {
  position: absolute;
  width: 984px;
  height: 965px;
  z-index: 0;
  left: 50%;
  top: 200px;
  margin-left: -492px;
}
.about-contact {
  position: relative;
  height: 830px;
  overflow: hidden;
}
.about-c-bg {
  position: absolute;
  width: 1920px;
  height: auto;
  z-index: 0;
  bottom: 0px;
  left: 0px;
}
.about-contact-way {
  display: block;
  text-align: center;
  margin: 50px 0px;
}

.about-cw-item {
  width: 420px;
  display: inline-block;
  margin-left: 80px;
}
.about-cw-icon {
  width: 74px;
  float: left;
}
.about-cw-msg {
  color: #fff;
  text-align: left;
  float: left;
  margin-left: 20px;
}

.about-cw-title {
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 15px;
}
.about-cw-txt {
  font-size: 16px;
}

.about-office {
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.office-item {
  margin: 0px 30px;
}
.office-img {
  width: 562px;
  height: 321px;
}
.office-title {
  font-size: 24px;
  color: #fff;
  margin-top: 35px;
  margin-bottom: 20px;
  color: #fff;
  text-align: center;
}

.office-txt {
  font-size: 16px;
  color: #fff;
  text-align: center;
}

.qualification-wrap {
  height: 420px;
  width: 1460px;
  position: relative;
  margin: 60px auto;
  color: #fff;
  font-size: 24px;
  overflow: hidden;
}
.quali-box {
  width: 220px;
  display: inline-block;
  margin: 0 70px;
}
.quali-img {
  width: 220px;
  height: 312px;
  background: rgba(135, 201, 245, 1);
}
.quali-name {
  font-size: 16px;
  font-weight: 400;
  margin-top: 21px;
  color: rgba(255, 255, 255, 1);
}

.pro-nav-bar-fixed {
  position: fixed;
  top: 0px;
  z-index: 1111;
}
.swiper-pagination-bullet {
  position: relative !important;
  width: 8px !important;
  height: 8px !important;
  margin: 0 32px !important;
  background-color: #fff !important;
  opacity: 0.5 !important;
}
.swiper-pagination-bullet-active {
  background: #007aff !important;
  opacity: 1 !important;
}

.m-about-containner {
  .sub-nav-box {
    width: 750px;
    .sub-nav-item {
      font-size: 20px;
      color: #fff;
      padding: 30px 10px;
      margin: 0px 5px;
    }
    .sub-nav-item-act {
      color: #fff;
      border-bottom: #7dbbd7 solid 3px;
    }
  }
  .about-intro {
    height: 655px;
    width: 750px;
    .about-intro-bg {
      width: 750px;
      height: auto;
    }
    .about-intr-txt {
      width: 650px;
      font-size: 24px;
      white-space: normal;
    }
  }
  .about-mission {
    width: 750px;
    height: 350px;
    align-items: flex-start;
    overflow: hidden;
    .mi-it-box {
      margin-top: 70px;
      width: 250px;
    }
    .mission-tip {
      width: 250px;
      margin: 0 auto;
      line-height: 27px;
      font-size: 16px;
    }
  }
  .about-course {
    .about-course-bg {
      width: 750px;
      height: auto;
      bottom: 0px;
      top: auto;
    }
    .about-course-box {
      width: 750px;
      height: 400px;
      margin-top: 50px;
      .course-box {
        padding-left: 30px;
        padding-top: 30px;
        height: 370px;
        .course-month {
          padding-top: 60px;
          .course-year {
            top: -120px;
            font-size: 62px;
          }
          .course-mon-name {
            font-size: 35px;
          }
          .course-mon-desc {
            font-size: 22px;
          }
        }
      }
    }
    .course-year-list {
      left: 60px;
      bottom: 60px;
    }
  }
  .about-msg-title {
    margin-top: 50px;
  }

  .about-quali {
    height: 460px;
    .qualification-wrap {
      width: 750px;
      height: 270px;
      .quali-box {
        width: 125px;
        margin: 0px 10px;
        .quali-img {
          width: 120px;
          height: 168px;
        }
      }
    }
  }
  .mulit-office {
    height: 777px;
    .muilt-office-bg {
      width: 750px;
      height: auto;
      margin-left: -375px;
      top: 150px;
    }
  }

  .about-contact {
    height: auto;

    .about-c-bg {
      width: 750px;
      height: auto;
    }
    .about-c-bg {
      top: auto;
      bottom: 0px;
    }
    .about-msg-box {
      position: relative;
      height: auto;
      .about-contact-way {
        overflow: hidden;
        .about-cw-item {
          margin: 0;
          width: 50%;
          float: left;
          .about-cw-msg {
            margin-left: 0px;
            float: none;
            text-align: center;
            .about-cw-title {
              margin-bottom: 10px;
            }
          }
        }
      }
      .about-office {
        display: block;
        .office-item {
          width: 750px;
          margin: 0px 0px 50px 0px;
          .office-img {
            width: 750px;
            height: auto;
          }
        }
      }
      .office-item {
        display: block;
        width: 750px;
      }
    }
  }
}
</style>
